import React, { useEffect, useState } from "react";
import paragraph from "../images/paragraph.svg";
import sms from "../images/sms.svg";
import facebook from "../images/icons/facebook.png";
import Google from "../images/icons/google.png";
import twitter from "../images/icons/twitter.png";
import instagram from "../images/icons/instagram.png";
import phone from "../images/phone.svg";
import { useNavigate } from "react-router-dom";
import { realEstateId, vehicleId } from "../utils/baseUrl";
import apiClient from "../utils/apiClient";
import moment from "moment";

const Footer = ({ strings }) => {
  const navigate = useNavigate();

  const [footerData, setFooterData] = useState(null);

  const getFooterData = async () => {
    try {
      const response = await apiClient.get(`/v1/user/get-footer-data`);
      setFooterData(response.data[0]);
    } catch (err) {
      setFooterData(null);
    }
  };

  const navigateToClassi = () => {
    window.open("https://www.classiconnect.uz", "_blank");
  };

  useEffect(() => {
    getFooterData();
  }, []);

  return (
    <footer className="bg-gray-200 py-10">
      <div className="container mx-auto px-4">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10">
          {/* Contact Us */}
          <div>
            <h6 className="text-xl font-semibold mb-4">{strings.contactUs}</h6>
            <div className="flex items-start gap-3 mb-4">
              <img src={paragraph} alt="Location" className="w-6" />
              <p>{footerData?.contactUs?.location}</p>
            </div>
            <div className="flex items-start gap-3 mb-4">
              <img src={phone} alt="Phone" className="w-6" />
              <p>{footerData?.contactUs?.phone}</p>
            </div>
            <div className="flex items-start gap-3">
              <img src={sms} alt="Email" className="w-6" />
              <p>{footerData?.contactUs?.email}</p>
            </div>
          </div>

          {/* Quick Links */}
          <div className="flex flex-col ">
            <h6 className="text-xl font-semibold mb-4">{strings.quickLinks}</h6>

            <div className="cursor-pointer hover:text-blue-500">
              {strings.home}
            </div>
            <div className="cursor-pointer hover:text-blue-500  py-1"
              onClick={() =>
                navigate("/realstate", {
                  state: { id: realEstateId },
                })
              }
            >
              {strings.realEstates}
            </div>
            <div className="cursor-pointer hover:text-blue-500  py-1"
              onClick={() =>
                navigate("/vehicle", {
                  state: { id: vehicleId },
                })
              }
            >
              {strings.vehicles}
            </div>
            <div className="cursor-pointer hover:text-blue-500  py-1"
              onClick={() => navigate("/aboutus")}
            >
              {strings.about}
            </div>
            <div className="cursor-pointer hover:text-blue-500  py-1"
              onClick={() => navigate("/privacy-policy")}
            >
              {strings.privacyPolicy}
            </div>

          </div>


          {/* Follow Us */}
          <div>
            <h6 className="text-xl font-semibold mb-4">{strings.followUs}</h6>
            <div className="flex gap-4">
              <a
                href={footerData?.socialMedia.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-110 transition-transform"
              >
                <img className="w-8" src={facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.egasi.net"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-110 transition-transform"
              >
                <img className="w-8" src={Google} alt="Google" />
              </a>
              <a
                href={footerData?.socialMedia.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-110 transition-transform"
              >
                <img className="w-8" src={twitter} alt="Twitter" />
              </a>
              <a
                href={footerData?.socialMedia.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:scale-110 transition-transform"
              >
                <img className="w-8" src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-10 border-t pt-4 text-center text-sm text-gray-600">
          <p>
            © {moment().format("YYYY")} {strings.allRights}. {strings.createdBy}{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={navigateToClassi}
            >
              Classi Connect
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
