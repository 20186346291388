import { jsPDF } from "jspdf";
import moment from "moment/moment";
import 'jspdf-autotable';

const exportToPdf = (data) => {
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF('p', 'pt', 'a4');
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const currentDate = new Date();

    let yPos = 40;
    const headTableColumns = ['Egasi.Net'];
    const headTableRows = [];

    headTableRows.push([
        `District :${data.location.district}`,
    ]);
    headTableRows.push([
        `City :${data.location.city}`,
    ]);
    headTableRows.push([
        `Country :${data.location.country}`,
    ]);

    doc.autoTable(headTableColumns, headTableRows, {
        startY: yPos,
        tableWidth: 250,
        theme: 'plain',
        margin: { left: 120 },
        styles: { fontSize: 8, cellPadding: 2 },
        headStyles: { fontSize: 14 },
    });

    doc.setFontSize(14);
    doc.setFont('Arial', 'bold');
    doc.setTextColor('#019B53');
    doc.text('Ad Details', pageWidth / 2 + 150, yPos + 10, { align: 'left' });
    doc.setTextColor('#000');
    const rightTableColumns = [''];
    const rightTableRows = [];

    rightTableRows.push([
        `Date: ${moment(currentDate).format('DD/MM/YYYY')}`,
    ]);

    doc.autoTable(rightTableColumns, rightTableRows, {
        startY: yPos + 10,
        margin: { left: pageWidth / 2 + 150 },
        tableWidth: 100,
        willDrawCell: false,
        theme: 'plain',
        styles: { fontSize: 8, cellPadding: 2 },
    });

    // doc.addImage(data.imageUrl[0], "JPEG", 100, 40, 180, 160);,

    yPos += 50
    doc.text("Details", 50, yPos + 26, { align: 'left' });

    const detailsTableCols = ['Field Name', "Value"];
    const detailsTableRows = [];

    data.basicInformation.details.forEach(element => {
        const detailedData = [
            element.fieldName,
            element.value
        ]
        detailsTableRows.push(detailedData)
    });

    doc.autoTable(detailsTableCols, detailsTableRows, {
        startY: yPos + 40,
        margin: { left: 50 },
        tableWidth: 300,
        willDrawCell: false,
        styles: { fontSize: 8, cellPadding: 2 },
        headStyles: {fillColor: "#F37521"}

    });

    yPos = doc.lastAutoTable.finalY;
    yPos += 30
    doc.text("Internal Features", 50, yPos, { align: 'left' });
    const internalFeaturesTableCols = [""];
    const internalFeaturesTableRows = [];

    data.internalFeatures.forEach(element => {
        const detailedData = [
            element.fieldName,
        ]
        internalFeaturesTableRows.push(detailedData)
    });

    doc.autoTable(internalFeaturesTableCols, internalFeaturesTableRows, {
        startY: yPos,
        margin: { left: 50 },
        tableWidth: 300,
        theme: "plain",
        willDrawCell: false,
        styles: { fontSize: 8, cellPadding: 2 },
    });

    yPos = doc.lastAutoTable.finalY;
    yPos += 30
    doc.text("External Features", 50, yPos, { align: 'left' });
    const externalFeaturesTableCols = [""];
    const externalFeaturesTableRows = [];

    data.externalFeatures.forEach(element => {
        const detailedData = [
            element.fieldName,
        ]
        externalFeaturesTableRows.push(detailedData)
    });

    doc.autoTable(externalFeaturesTableCols, externalFeaturesTableRows, {
        startY: yPos,
        margin: { left: 50 },
        tableWidth: 300,
        theme: "plain",
        willDrawCell: false,
        styles: { fontSize: 8, cellPadding: 2 },
    });


    yPos += 15

    doc.save("adDetails.pdf");
}

export default exportToPdf
