import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import { baseURL1 } from "../../utils/baseUrl";
import ClipLoader from "react-spinners/ClipLoader";
import apiClient from "../../utils/apiClient";
import { useSelector } from "react-redux";
import strings from "../../utils/locals/languages";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import area from "../../images/ar.svg";
import beds from "../../images/bedss.svg";
import wash from "../../images/wshs.svg";
import Slider from "@mui/material/Slider";
import MapComponent from "./MapView";
import locationIcon from "../../images/location.svg";
import arowDown from "../../images/arrowDown.svg";
import listIcon from "../../images/listIcon.svg";
import redLocationIcon from "../../images/redLocationIcon.svg";
import Cookies from "js-cookie";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Footer from "../../components/Footer";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleRealEstateClick } from "../../services/handlers";

const customSelect = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 50,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    outline: "none",
    border: 'none'

  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ddd' : null,
    color: state.isFocused ? '#333' : null,
  }),
}

const Body = ({ id }) => {
  const [showFilters, setShowFilters] = useState(false)
  const [viewType, setViewType] = useState("grid");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showMap, setShowMap] = useState(false);
  const numbers = ["1", "2", "3", "4", "5", "6", "7+"];
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [city, setCity] = useState("");
  const [purpose, setPurpose] = useState("");
  const [data, setData] = useState([]);
  const [reLoad, setReLoad] = useState(0);
  const [district, setDistrict] = useState("");
  const [value1, setValue1] = useState([0, 10000000]);
  const [value2, setValue2] = useState([0, 10000000]);
  const [priceRange, setPriceRange] = useState();
  const [areaRange, setAreaRAnge] = useState();
  const [neighborhood, setNeighborhood] = useState("");
  const [nearBy, setNearBy] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [detailedData, setDetailedData] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [selectedBathNumber, setSelectedBathNumber] = useState("");
  const [selectedBedNumber, setSelectedBedNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCountryTitle, setSelectedCountryTitle] = useState('')
  const [selectedCities, setSelectedCities] = useState([]);

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [selectedSubCategories, setSelectedSubCategories] = useState([])

  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const [topSubCategories, setTopSubCategories] = useState([])
  const [topSubCategoriesLoading, setTopSubCategoriesLoading] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [totalAds, setTotalAds] = useState(0)

  const handleSelectChange = (fieldName, selectedValue) => {
    setSelectedFilterOptions(prevState => ({
      ...prevState,
      [fieldName]: selectedValue,
    }));
  };

  const clearFilters = async () => {
    window.location.reload()
    setSelectedCountry('')
    setSelectedCountryTitle('')
    setSelectedCities([])
    setSelectedDistricts([])
    setSelectedCategories([])
    setSelectedSubCategories([])
    setValue1([0, 100000000])
    setValue2([0, 100000000])
    setFieldValues([])
  }

  useEffect(() => {
    setTopSubCategoriesLoading(true)
    axios
      .post(
        `${baseURL1}/v1/adds/realestate-cat-dropdown-with-multiple-categories?language=${strings._language}`, {
        categoryIds: id == 'all' ? [] : [`${id}`]
      }
      )
      .then((response) => {
        setTopSubCategoriesLoading(false)
        setTopSubCategories(response.data)
        let total = 0;
        response.data.forEach(element => {
          total += element.totalAdds;
        });
        setTotalAds(total)
      })
      .catch((err) => { });
  }, [strings._language]);

  useEffect(() => {

    const cityIds = []

    selectedCities.forEach((item) => cityIds.push(item.id))

    axios
      .post(`${baseURL1}/v1/adds/districtDropdown`, {
        cityIds
      })
      .then((response) => {

        const original = response.data

        const modified = original.map((dist, index) => ({
          id: dist._id,
          label: dist.districtTitle,
          value: dist.districtTitle
        }));

        setDistricts(modified);
      })
      .catch((err) => { });
  }, [selectedCities]);

  useEffect(() => {

    const countryIds = []

    selectedCountries.forEach((item) => countryIds.push(item.id))

    axios
      .get(`${baseURL1}/v1/adds/citiesDropdown/${selectedCountry}`, {
        countryIds
      })
      .then((response) => {

        const original = response.data.cities;

        const modified = original.map((city, index) => ({
          id: city._id,
          label: city.cityTitle,
          value: city.cityTitle
        }));

        setCities(modified);
      })
      .catch((err) => { });
  }, [selectedCountry]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/countiesDropdown`)
      .then((response) => {
        setCountries(response.data);
      })
      .catch((err) => { });
  }, [strings._language]);

  const handlePriceRange = (event, newValue, activeThumb) => {
    const minDistance = 10;
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const handleAreaRange = (event, newValue, activeThumb) => {
    const minDistance = 15;
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue2([Math.min(newValue[0], value2[1] - minDistance), value2[1]]);
    } else {
      setValue2([value2[0], Math.max(newValue[1], value2[0] + minDistance)]);
    }
  };

  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        setReLoad(reLoad + 1);
      })
      .catch((err) => {
        toast.error(`${strings.failedToAdd}`);
      });
  };

  const searchFilter = () => {

    const cities = []
    const district = []
    const category = []
    const floorLocation = []
    const fromwhom = []
    const furnished = []
    const facade = []
    const insidecomplex = []
    const heatingtype = []
    const buildingage = []
    const buildingfloors = []
    const deedstatus = []

    selectedCities.forEach((item) => (
      cities.push(item.label)
    ))

    selectedDistricts.forEach((item) => (
      district.push(item.label)
    ))

    selectedCategories.forEach((item) => (
      category.push(item.id)
    ))

    selectedFilterOptions?.FloorLocation?.forEach((item) => {
      floorLocation.push(item.label)
    })

    selectedFilterOptions?.FromWhom?.forEach((item) => {
      fromwhom.push(item.label)
    })

    selectedFilterOptions?.Furnished?.forEach((item) => {
      furnished.push(item.label)
    })

    selectedFilterOptions?.Facade?.forEach((item) => {
      facade.push(item.label)
    })

    selectedFilterOptions?.InsideComplex?.forEach((item) => {
      insidecomplex.push(item.label)
    })

    selectedFilterOptions?.HeatingSystem?.forEach((item) => {
      heatingtype.push(item.label)
    })

    selectedFilterOptions?.BuildingAge?.forEach((item) => {
      buildingage.push(item.label)
    })

    selectedFilterOptions?.BuildingFloors?.forEach((item) => {
      buildingfloors.push(item.label)
    })

    selectedFilterOptions?.TitleDeedStatus?.forEach((item) => {
      deedstatus.push(item.label)
    })


    setIsLoading(true);
    apiClient
      .post(
        `/v1/adds/advance-filter-adds?section=638f25154cb7c29ec6ad4bfe&c&language=${strings._language}`, {
        country: selectedCountryTitle,
        cities,
        district,
        minPrice: value1[0],
        maxPrice: value1[1],
        area: value2[0],
        maxArea: value2[1],
        category: selectedCategories.length === 0 ? id === 'all' ? [] : [`${id}`] : selectedCategories.filter(item => item !== 'all'),
        brand: (selectedSubCategories.length === 0 && selectedSubCategory !== null) ? selectedSubCategory === 'all' ? [] : [`${selectedSubCategory}`] : selectedSubCategories.filter(item => item !== 'all'),
        floorLocation,
        fromwhom,
        furnished,
        facade,
        insidecomplex,
        heatingtype,
        buildingage,
        buildingfloors,
        deedstatus

      }
      )
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    searchFilter();
  }, [
    selectedCountries, cities, districts, neighborhood, value1, value2, selectedCategories, selectedFilterOptions, selectedSubCategory
  ]);

  const geopoints = data.map((realEstate) => ({
    coordinates: {
      lat: realEstate.location.geoPoints.coordinates[1],
      lng: realEstate.location.geoPoints.coordinates[0],
    },
    price: `${realEstate.basicInformation.price.currency} ${realEstate.basicInformation.price.price}`,
    item: realEstate,
  }));

  useEffect(() => {

    const categoryIds = []

    selectedCategories.forEach((item) => (
      categoryIds.push(item.id)
    ))

    axios
      .post(
        `${baseURL1}/v1/adds/realestate-cat-dropdown-with-multiple-categories?language=${strings._language}`, {
        categoryIds
      }
      )
      .then((response) => {
        const original = response.data

        const modified = original.map((brand, index) => ({
          id: brand._id,
          label: brand.title,
          value: brand.title
        }));

        setSubCategories(modified);
      })
      .catch((err) => { });
  }, [selectedCategories]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        const original = response.data[1].categories;

        const modified = original.map((cat, index) => ({
          id: cat._id,
          label: cat.title,
          value: cat.title
        }));

        setCategories(modified);
      })
      .catch((err) => { });
  }, [strings._language]);




  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseURL1}/v1/adds/section/feature?section=realEstate&language=${strings._language}`
      )
      .then((res) => {
        const myData = res.data.detail.filter(
          (item) =>
            item.fieldName === "From Whom" ||
            item.fieldName === "Furnished" ||
            item.fieldName === "Building Age" ||
            item.fieldName === "Building Floors" ||
            item.fieldName === "Floor Location" ||
            item.fieldName === "Title Deed Status" ||
            item.fieldName === "Heating System" ||
            item.fieldName === "Inside Complex" ||
            item.fieldName === "Facade" ||
            item.fieldName === "Расположение этажа" ||
            item.fieldName === "От кого" ||
            item.fieldName === "Меблированная" ||
            item.fieldName === "Фасад" ||
            item.fieldName === "Система отопления" ||
            item.fieldName === "Возраст здания" ||
            item.fieldName === "Этажи зданий" ||
            item.fieldName === "Количество этажей" ||
            item.fieldName === "Статус правоустанавливающего документа" ||
            item.fieldName === "Kimdan" ||
            item.fieldName === "Mebel bilan jihozlangan" ||
            item.fieldName === "Fasad" ||
            item.fieldName === "Ichki majmua" ||
            item.fieldName === "Isitish tizimi" ||
            item.fieldName === "Qurilish yoshi" ||
            item.fieldName === "Building floors" ||
            item.fieldName === "Qavatlar soni" ||
            item.fieldName === "Qavat joylashuvi"
        );
        setDetailedData(myData);
      });
  }, [strings._language]);

  const itemsToShow = 8

  const itemWidth = 300
  const [scrollPosition, setScrollPosition] = useState(0);


  const nextButtonClickHandler = () => {
    const nextPosition = Math.min(
      scrollPosition + itemWidth,
      ((topSubCategories.length + 2) - itemsToShow) * itemWidth
    );

    setScrollPosition(nextPosition);
    // if (isEndReached >= scrollPosition) {
    //   setScrollPosition(nextPosition);
    // }
  };

  const prevButtonClickHandler = () => {
    const prevPosition = Math.max(scrollPosition - itemWidth, 0);
    setScrollPosition(prevPosition);
  };



  return (
    <div>
      <div className="container relative mx-auto">
        <div className="w-full md:hidden flex justify-end">
          <div className="bg-[#F37521] cursor-pointer text-white p-2 rounded-md "
            onClick={() => {
              if (showFilters) {
                setShowFilters(false)
              } else {
                setShowFilters(true)
              }
            }}
          >
            Show Filters
          </div>
        </div>
        <div className="flex justify-between ">
          <h4 className="uppercase font-semibold text-[20px] mb-4">
            {strings.subCate}
          </h4>
          <div
            className="absolute z-50 top-32 -left-10 "
          >
            <ArrowBackIosNewIcon
              className={`cursor-pointer   rounded-full p-1 bg-white text-[#F37521] `}
              onClick={() => prevButtonClickHandler()}
              style={{
                height: 48,
                width: 48
              }}
            />
          </div>

          <div
            className="absolute z-50 top-32 -right-14 "
          >
            <ArrowForwardIosIcon
              className={`cursor-pointer   rounded-full p-1 bg-white text-[#F37521] `}
              onClick={() => nextButtonClickHandler()}
              style={{
                height: 48,
                width: 48
              }}
            />
          </div>
        </div>
        {topSubCategoriesLoading ? (
          <div
            className="flex gap-[52px] flex-nowrap overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100"
            style={{ width: "100%" }}
          >
            {[1, 2, 3, 4].map((_, index) => (
              <div
                className="rounded-2xl shadow-md bg-white w-[200px]  flex-none mb-3 cursor-pointer"
                key={index}
              >
                <Skeleton
                  style={{
                    height: "200px",
                    width: "100%",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                  className="max-h--sm h-60  shadow-sm"
                />
                <div className="text-center text-sm font-medium py-1">
                  <Skeleton height={10} width={50} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className='gap-4 flex p-2 bg-transparent overflow-y-auto'
          >
            <div
              className={`flex w-[400px] flex-col shadow-md rounded-xl  cursor-pointer ${selectedSubCategory === 'all' ? 'border-2 border-orange-500' : ''}`}
              style={{
                transition: 'transform 0.3s',
                transform: `translateX(-${scrollPosition}px)`,
              }}
            onClick={() => setSelectedSubCategory('all')}
            >

              <div className="w-[200px] bg-white rounded-2xl shadow-sm">
                <img
                  src='https://egasi-storage.s3.ap-south-1.amazonaws.com/1730355973038_ltv68bnu_realestate-removebg-preview.png'
                  alt="All Categories"
                  className="h-[150px] w-[100%] rounded-2xl shadow-sm object-cover"
                />
              </div>
              <h6 className="text-center text-sm font-extrabold py-1">
                {strings.all}  <span className="text-orange-400"> ({totalAds})</span>
              </h6>
            </div>
            {topSubCategories.length !== 0 && topSubCategories?.sort((a, b) => a.title.localeCompare(b.title))?.map((item, index) => (
              <div
                className={`flex w-[400px] flex-col shadow-md rounded-xl  cursor-pointer ${selectedSubCategory === item._id ? 'border-2 border-orange-500' : ''}`}
                style={{
                  transition: 'transform 0.3s',
                  transform: `translateX(-${scrollPosition}px)`,
                }}
                key={index}
                onClick={() => setSelectedSubCategory(item._id)}

              >

                <div className="w-[200px] bg-white rounded-2xl shadow-sm">
                  <img
                    src={item.imageUrl}
                    alt="Category"
                    className="h-[150px] bg-transparent min-w-full object-contain rounded-2xl shadow-sm"
                  />
                </div>
                <h6 className="text-center text-sm font-extrabold py-1">
                  {item.title}  <span className="text-orange-400"> ({item.totalAdds})</span>
                </h6>
              </div>
            ))}
          </div>
        )}
        <div className={`md:flex ${!showFilters && 'hidden'} justify-between items-center flex-col sm:flex-col md:flex-row pb-4`}>
          <h3 className="mt-3 text-gray-600">{strings.filters}</h3>
          <div className=" flex gap-3 justify-end flex-col items-center md:flex-row mt-3">
            <div className="flex md:w-max w-[200px] justify-center rounded-full  gap-3 justify-items-center cursor-pointer"
              style={{
                border: showMap ? "1px solid #F77A23" : "1px solid black",
                padding: "14px",
              }}
            >
              <ImportExportIcon style={{ height: "18px", width: "18px" }} />
              <select
                className=" text-sm font-semibold outline-none bg-transparent tracking-wide"
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
                value={sortBy}
                style={
                  {
                    // fontSize: 18,
                  }
                }
              >
                <option value="">{strings.sortBy}</option>
                <option value="ph">{strings.priceHighest}</option>
                <option value="pl">{strings.priceLowest}</option>
                <option value="new">{strings.newest}</option>
                <option value="old">{strings.oldFirst}</option>
                <option value="atoz">{strings.alpAZ}</option>
                <option value="ztoa">{strings.alpZA}</option>
              </select>
            </div>
            <div
              className="flex md:w-max w-[200px]  justify-center rounded-full  gap-3 justify-items-center cursor-pointer "
              style={{
                border: showMap ? "1px solid #F77A23" : "1px solid black",
                padding: "10px",
              }}
              onClick={() => {
                setShowMap(true);
              }}
            >

              <img
                className="w-5 h-6 mt-1"
                src={showMap ? redLocationIcon : locationIcon}
                alt=""
              />
              <button
                style={{
                  color: showMap ? "#F77A23" : "#000",
                }}
              >
                {strings.mapView}
              </button>
            </div>

            <div
              className="flex md:w-max w-[200px]  justify-between h-12 p-1 px-2  rounded-full gap-2 justify-items-center cursor-pointer"
              style={{ border: "1px solid black" }}
              onClick={() => {
                setShowMap(false);
              }}
            >
              <img
                className=" h-4  ms-1"
                src={listIcon}
                alt=""
                style={{ marginTop: "11px" }}
              />
              <button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {strings.viewType}
              </button>
              <img className="w-3" src={arowDown} alt="" />

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setViewType("grid");
                    handleClose();
                  }}
                >
                  Grid View
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setViewType("list");
                    handleClose();
                  }}
                >
                  List View
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="col-md  row">
          <div className={`col-lg-3 md:block md:mt-0 mt-4 ${!showFilters && 'hidden'} col-md-4 col-sm-6`}>
            <div className="bg-white rounded-xl shadow-md  p-3 ">
              <h5 className="text-gray-500">{strings.locationFilter}</h5>

              <select
                type="text"
                className="text-gray-500 bg-white text-sm"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: 20,
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  outline: "none",
                  marginTop: 12,
                }}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  const selectedOption = countries.find((option) => option._id === selectedId);

                  if (selectedOption) {
                    setSelectedCountry(selectedId);
                    setSelectedCountryTitle(selectedOption.countryTitle);
                  }
                }}
                value={selectedCountry} // Assuming you want to control the selected value
              >
                <option>{strings.select}</option>
                {countries.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.countryTitle.charAt(0).toUpperCase() + option.countryTitle.slice(1)}
                  </option>
                ))}

              </select>

              <h6 style={{ marginTop: "10px" }} className="text-gray-500">
                {strings.city}
              </h6>

              <Select
                id="brand"
                options={cities}
                value={selectedCities}
                onChange={setSelectedCities}
                labelledBy="Select"
                styles={customSelect}
                isMulti

              />

              <h6 style={{ marginTop: "10px" }} className="text-gray-500">
                {strings.distt}
              </h6>

              <Select
                options={districts}
                value={selectedDistricts}
                onChange={setSelectedDistricts}
                labelledBy="Select"
                styles={customSelect}
                isMulti

              />

              <input
                type="text"
                className=" text-gray-400 bg-white text-sm"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: 20,
                  boxShadow:
                    " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  // fontSize: 18,
                  outline: "none",
                  marginTop: 12,
                }}
                onChange={(e) => setNeighborhood(e.target.value)}
                value={neighborhood}
                placeholder={strings.neigh}
              />


              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.priceRange}
              </h6>

              <div className="flex gap-3 justify-around">
                <input
                  type="text"
                  className=" text-gray-400 bg-white text-sm"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue1([e.target.value, value1[1]])}
                  value={value1[0]}
                />

                <input
                  type="text"
                  className=" text-gray-400 bg-white text-sm"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue1([value1[0], e.target.value])}
                  value={value1[1]}
                />
              </div>

              <div className="mt-4 mx-2">
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={value1}
                  onChange={handlePriceRange}
                  getAriaValueText={priceRange}
                  style={{
                    color: "orange",
                  }}
                  min={0}
                  max={1000000}
                />
              </div>
              <div className="flex justify-between">
                <div>{value1[0]}</div>
                <div>{value1[1]}</div>
              </div>

              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.areaRange}
              </h6>

              <div className="flex gap-2 justify-around">
                <input
                  className=" text-gray-400 bg-white text-sm"
                  type="text"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue2([e.target.value, value2[1]])}
                  value={value2[0]}
                />

                <input
                  className=" text-gray-400 bg-white text-sm"
                  type="text"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue2([value2[0], e.target.value])}
                  value={value2[1]}
                />
              </div>

              <div className="mt-4 mx-2">
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={value2}
                  onChange={handleAreaRange}
                  getAriaValueText={areaRange}
                  style={{
                    color: "orange",
                  }}
                  min={0}
                  max={1000000}
                />
              </div>
              <div className="flex justify-between">
                <div>{value2[0]}</div>
                <div>{value2[1]}</div>
              </div>

              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.bedrooms}
              </h6>

              <div className="flex p-1 overflow-scroll">
                {numbers.map((number) => (
                  <div
                    key={number}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      borderRadius: "50%",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      fontSize: 12,
                      outline: "none",
                      cursor: "pointer",
                      marginLeft: "4px",
                      backgroundColor:
                        selectedBedNumber === number ? "orange" : "",
                      color: selectedBedNumber === number ? "white" : "",
                    }}
                    onClick={() => {
                      setBedrooms(number);
                      setSelectedBedNumber(number);
                    }}
                  >
                    {number === 7 ? `${number}` : number}
                  </div>
                ))}
              </div>

              <h6 style={{ marginTop: "30px" }}>{strings.bathrooms}</h6>
              <div className="flex p-1 overflow-scroll">
                {numbers.map((number) => (
                  <div
                    key={number}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      borderRadius: "50%",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      fontSize: 12,
                      outline: "none",
                      cursor: "pointer",
                      marginLeft: "4px",
                      backgroundColor:
                        selectedBathNumber === number ? "orange" : "",
                      color: selectedBathNumber === number ? "white" : "",
                    }}
                    onClick={() => {
                      setBathrooms(number);
                      setSelectedBathNumber(number);
                    }}
                  >
                    {number === 7 ? `${number}` : number}
                  </div>
                ))}
              </div>
              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.categories}
              </h6>
              <Select
                options={categories}
                value={selectedCategories}
                onChange={setSelectedCategories}
                labelledBy="Select"
                styles={customSelect}
                isMulti
              />


              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.subCate}
              </h6>



              <Select
                options={subCategories}
                value={selectedSubCategories}
                onChange={setSelectedSubCategories}
                styles={customSelect}
                isMulti

              />

              {detailedData.map((item) => {
                const transformedOptions = item?.dropDown?.map((value) => ({
                  label: value,
                  value: value,
                }));
                return (
                  <>
                    <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                      {item.fieldName}
                    </h6>

                    <Select
                      options={transformedOptions}
                      selected={selectedFilterOptions[item.fieldName.replace(/\s/g, '')]} // Pass selected option from state
                      onChange={(selectedValue) => handleSelectChange(item.fieldName.replace(/\s/g, ''), selectedValue)}
                      labelledBy="Select"
                      styles={customSelect}
                      isMulti
                    />

                  </>
                )
              })}
            </div>
            <div className="bg-[#F37521] hover:bg-[#F17522] mt-3 p-2 text-center rounded-md text-white cursor-pointer"
              onClick={() => clearFilters()}
            >
              Clear Filters
            </div>
          </div>

          <div className="col-lg-9 md:mt-0 mt-4  col-md-8 col-sm-6">
            {!showMap ? (
              <>
                {isLoading ? (
                  <div className="container flex justify-center mt-10">
                    <div>
                      <ClipLoader
                        loading={isLoading}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  </div>
                ) : (
                  <div className=" ">
                    {data.length === 0 ? (
                      <div className="flex justify-center">
                        <h3>{strings.norec}</h3>
                      </div>
                    ) : (
                      <div
                        className={`${viewType === "grid"
                          ? "grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4"
                          : "grid md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4"
                          }`}
                      >
                        {data
                          .sort((a, b) => {
                            if (sortBy === "ph") {
                              const priceA = Number(a?.basicInformation?.price?.price?.replace(/,/g, ''));
                              const priceB = Number(b?.basicInformation?.price?.price?.replace(/,/g, ''));

                              return priceB - priceA;
                            } else if (sortBy === "pl") {
                              const priceA = Number(a?.basicInformation?.price?.price?.replace(/,/g, ''));
                              const priceB = Number(b?.basicInformation?.price?.price?.replace(/,/g, ''));

                              return priceA - priceB;
                            } else if (sortBy === "atoz") {
                              return a?.basicInformation?.title?.localeCompare(
                                b?.basicInformation?.title
                              ); // Sort A to Z
                            } else if (sortBy === "ztoa") {
                              return b?.basicInformation?.title?.localeCompare(
                                a?.basicInformation?.title
                              ); // Sort Z to A
                            } else if (sortBy === "new") {
                              return (
                                new Date(b.createdAt) - new Date(a.createdAt)
                              ); // Sort by newest createdAt
                            } else if (sortBy === "old") {
                              return (
                                new Date(a.createdAt) - new Date(b.createdAt)
                              ); // Sort by oldest createdAt
                            } else {
                              // Handle other sorting options if needed
                              return 0; // No sorting
                            }
                          })
                          .map((item, index) => {
                            return (
                              <div
                                className={`ca  flex ${viewType === "list"
                                  ? "flex-row w-full"
                                  : "flex-col"
                                  } shadow-xl rounded-xl border-none cursor-pointer`}
                                style={
                                  {
                                    // borderTopRightRadius: 12,
                                    // borderTopLeftRadius: 12,
                                    // boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                                  }
                                }
                                onClick={() => {
                                  handleRealEstateClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.location.city, item.location.district)
                                }}
                              >
                                <div
                                  className={`inline-block ${viewType === "list"
                                    ? "w-1/2 h-[185px]"
                                    : "relative h-52"
                                    }`} // style={{ height: "200px", overflow: "hidden" }}
                                >
                                  {viewType !== "list" && // Hide icons when viewType is "list"
                                    (!item.fav.includes(userId) ? (
                                      <FavoriteBorderOutlinedIcon
                                        className="absolute items-content-end w-12"
                                        style={{
                                          right: "8px",
                                          color: "#F37521",
                                          backgroundColor: "white",
                                          borderRadius: 100,
                                          padding: 3,
                                          top: 8,
                                        }}
                                        onClick={(event) =>
                                          addToFavorites(event, item._id)
                                        }
                                      />
                                    ) : (
                                      <FavoriteOutlinedIcon
                                        className="absolute items-content-end w-12"
                                        style={{
                                          right: "8px",
                                          color: "red",
                                          backgroundColor: "white",
                                          borderRadius: 100,
                                          padding: 3,
                                          top: 8,
                                        }}
                                        onClick={(event) =>
                                          addToFavorites(event, item._id)
                                        }
                                      />
                                    ))}
                                  <img
                                    className={`${viewType === "list"
                                      ? "rounded-tl-xl rounded-bl-xl"
                                      : "rounded-tr-xl rounded-tl-xl"
                                      }`}
                                    src={item.coverPhoto}
                                    alt="random"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                      // borderTopRightRadius: 12,
                                      // borderTopLeftRadius: 12,
                                    }}
                                  />
                                </div>
                                <div
                                  className={`px-[10px] ${viewType === "list"
                                    ? "px-4 w-1/2 space-y-2 pt-1"
                                    : ""
                                    }`}
                                >
                                  <div
                                    className="flex items-center mt-1 justify-between  "
                                  // style={{ height: "30%" }}
                                  >
                                    <div>
                                      <h3
                                        className={`font-medium text-sm  ${viewType === "list" ? " text-xl" : ""
                                          }`}
                                      >
                                        {item?.basicInformation?.title?.slice(0, 15)}
                                      </h3>
                                    </div>
                                    <div
                                      className={`${viewType === "list" ? "hidden" : ""
                                        }`}
                                      style={{ color: "#F37521" }}
                                    >
                                      {viewType === "grid" && ( // Only render when viewType is "list"
                                        <div className="flex">
                                          <h1 className="font-bold text-sm ">
                                            {item.basicInformation.price.currency}&nbsp;
                                          </h1>
                                          <h1 className="font-bold text-sm ">
                                            {Number(item.basicInformation.price.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                          </h1>
                                        </div>
                                      )}
                                    </div>
                                    {viewType === "list" && // Hide icons when viewType is "list"
                                      (!item.fav.includes(userId) ? (
                                        <FavoriteBorderOutlinedIcon
                                          className=" items-content-end w-12"
                                          style={{
                                            right: "8px",
                                            color: "#F37521",
                                            backgroundColor: "white",
                                            borderRadius: 100,
                                            padding: 3,
                                            top: 8,
                                          }}
                                          onClick={(event) =>
                                            addToFavorites(event, item._id)
                                          }
                                        />
                                      ) : (
                                        <FavoriteOutlinedIcon
                                          className=" items-content-end w-12"
                                          style={{
                                            right: "8px",
                                            color: "red",
                                            backgroundColor: "white",
                                            borderRadius: 100,
                                            padding: 3,
                                            top: 8,
                                          }}
                                          onClick={(event) =>
                                            addToFavorites(event, item._id)
                                          }
                                        />
                                      ))}
                                  </div>

                                  <div
                                    className="flex ga -mb-4"
                                  // style={{ height: "30%" }}
                                  >
                                    <h1 className="text-[9px] font-[500] text-gray-400 ">
                                      {item?.basicInformation?.description?.slice(
                                        0,
                                        15
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    className={`${viewType === "grid" ? "hidden" : ""
                                      }`}
                                    style={{ color: "#F37521" }}
                                  >
                                    {viewType === "list" && ( // Only render when viewType is "list"
                                      <div className="flex">
                                        <p className={` font-extrabold  text-lg`}>
                                          {item?.basicInformation?.price?.currency}&nbsp;
                                        </p>
                                        <p className={` font-bold text-lg`}>
                                          {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={`flex text-[10px] text-gray-400 items-center -mb-1 ${viewType === "list"
                                      ? "text-[12px] justify-between pr-8"
                                      : "justify-between"
                                      }`}
                                  >
                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={beds}
                                        alt="year"
                                        className={`h-3  w-3 ${viewType === "list" ? "h-6 w-6" : ""
                                          }`}
                                      />
                                      <p className="text- pt-3">
                                        {item?.basicInformation?.details
                                          .filter((item) =>
                                            [
                                              "Bedrooms",
                                              "Спальни",
                                              "Yotoq xonalari",
                                            ].includes(item.fieldName)
                                          )
                                          .map((filteredItem, index) => (
                                            <span key={index}>
                                              {filteredItem.value === ""
                                                ? 1
                                                : filteredItem?.value}
                                            </span>
                                          ))}
                                      </p>
                                    </div>

                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={area}
                                        alt="year"
                                        className={`h-3  w-3 opacity-60 ${viewType === "list" ? "h-6 w-6" : ""
                                          }`} />
                                      <p className="tex pt-3">
                                        {item?.basicInformation?.area?.net}m <sup>2</sup>
                                      </p>
                                    </div>

                                    <div className="flex gap-2 items-center">
                                      <img
                                        src={wash}
                                        alt="year"
                                        className={`h-3  w-3 opacity-60 ${viewType === "list" ? "h-6 w-6" : ""
                                          }`}
                                      />
                                      <p className="text pt-3">
                                        {item?.basicInformation?.details
                                          .filter((item) =>
                                            [
                                              "Bathrooms",
                                              "Ванные комнаты",
                                              "Hammomlar",
                                            ].includes(item.fieldName)
                                          )
                                          .map((filteredItem, index) => (
                                            <span key={index}>
                                              {filteredItem.value}
                                            </span>
                                          ))}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <MapComponent geopoints={geopoints} />
            )
            }
          </div >
        </div >
        <Footer strings={strings} />
      </div >
    </div >
  );
};

export default Body;