import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTimes } from 'react-icons/fa';
import { useLocation } from "react-router-dom";
import apiClient from "../../utils/apiClient";
import { baseURL1, uploadDataUrl, vehicleId } from "../../utils/baseUrl";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";
import strings from "../../utils/locals/languages";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import OpenStreetMap from "../../components/OpenStreetMap";
import CurrencyInput from "react-currency-input-field";

const EditVehicle = () => {
  const [isLocationAvailable, setIsLocationAvailable] = useState(true)
  const [category, setCategory] = useState("")
  const [model, setModel] = useState("")
  const [brand, setBrand] = useState("")
  const [city, setCity] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [district, setDistrict] = React.useState(null);
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [notificationData, setNotificationData] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)
  const [myData, setMyData] = useState([])

  const [videoFile, setVideoFile] = useState("")
  const imageUrl = []
  const [videoLoading, setVideoLoading] = useState(false)
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  const [menu1Open, setMenu1Open] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false)
  const hiddenCoverFileInput = useRef(null);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState('')
  const [dropDownData, setDropDownData] = useState([])





  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };
  const handleCoverPhotoClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const uploadCoverPhoto = (File) => {
    setCoverPhotoLoading(true);
    const formData = new FormData();
    formData.append("files", File);

    axios
      .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setCoverPhotoLoading(false);
        formik.setFieldValue('coverPhoto', res.data[0])

        toast.success(`${strings.imageUpload}`);
      })
      .catch((err) => {
        toast.error(`${strings.imageUploadFailed}`);
      });
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const [updatedInternaFeatures, setUpdatedInternaFeatures] = useState()
  const [updatedExternalFeatures, setUpdatedExternalFeatures] = useState()
  const [updatedSafetyFeatures, setUpdatedSafetyFeatures] = useState()

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [models1, setModels1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showExternalModal, setExternalShowModal] = useState(false);
  const [showSafetyModal, setSafetyShowModal] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getInternalFeatures, setGetInternalFeatures] = useState([]);
  const [getExternalFeatures, setGetExternalFeatures] = useState([]);
  const [getsafetyFeatures, setGetSafetyFeatures] = useState([]);
  const [internalFeatures, setInternalFeatures] = useState([]);
  const [externalFeatures, setExternalFeatures] = useState([]);
  const [safetyFeatures, setSafetyFeatures] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newInternalFeature, setNewInternalFeature] = useState("");
  const [newExternalFeature, setNewExternalFeature] = useState("");
  const [newSafetyFeature, setNewSafetyFeature] = useState("");
  const [detailedDataLength, setDetailedDataLength] = useState(0)
  const location = useLocation();
  const id = location.state.id;
  const editData = location.state.data;
  const manualAd = location.state.manualAd;
  const basicInfo = location.state.basicInfo;

  const [required, setRequired] = useState(false)

  const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const hiddenVideoFileInput = React.useRef(null);
  const [files, setFiles] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);

  const [whatsapp, setWhatsapp] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [sms, setSms] = useState(false);
  const [call, setCall] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const isEditOrCreate = location.state.isEditOrCreate;

  const handleOptionChange = (event) => {
    const name = event.target.name; // get the name of the radio button
    const fieldvalue = event.target.value; // get the value of the radio button
    const selectedOption = {
      fieldName: name,
      value: fieldvalue,
    };
    const selectedOptions = formik.values.bodyParts;

    if (selectedOptions.includes(selectedOption)) {
      formik.setFieldValue(
        "bodyParts",
        selectedOptions.filter((option) => option !== selectedOption)
      );
    } else {
      formik.setFieldValue("bodyParts", [...selectedOptions, selectedOption]);
    }
  };

  const handleVideoUpload = (File) => {
    setVideoLoading(true)
    const formData = new FormData();
    formData.append("files", File);

    axios
      .post(
        `${uploadDataUrl}/v1/adds/upload-multiple`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setVideoFile(res.data[0]);
        setVideoLoading(false)
        formik.setFieldValue("videoUrl", res.data[0])
        formik.setFieldValue("videoThumbnail", res.data[0])
        toast.success(`${strings.videoUpload}`)
      })
      .catch((err) => {
        setVideoLoading(false)
        toast.error(`${strings.videoUploadFailed}`)
      });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleVideoClick = (event) => {
    hiddenVideoFileInput.current.click();
  };



  const handleImageUpload = (files) => {
    Array.from(files).forEach((file) => {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          const { width, height } = img;

          if (width < 500 || height < 500) {
            toast.error("Please select an image with height and width greater than 500px");
            return;
          }

          // Proceed with the upload if dimensions are as expected
          const formData = new FormData();
          formData.append("files", file);
          setImageLoading(true);

          axios
            .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              setImageLoading(false);

              setFiles((prevFiles) => [
                ...prevFiles,
                {
                  image: res.data[0],
                },
              ]);


              toast.success(`${strings.imageUpload}`);
            })
            .catch((err) => {
              setImageLoading(false);
              toast.error(`${strings.imageUploadFailed}`);
            });
        };

        img.onerror = () => {
          setImageLoading(false);
          toast.error("Invalid image file.");
        };

        img.src = e.target.result;
      };

      fileReader.onerror = () => {
        setImageLoading(false);
        toast.error("Failed to read the file.");
      };

      fileReader.readAsDataURL(file);
    });
  };

  const validationSchema = Yup.object().shape({
    purpose: Yup.string().required(`${strings.selectPurpose}`),
    basicInformation: Yup.object().shape({
      title: Yup.string()
        .min(5, `${strings.titleErrorMsgOne}`)
        .required(`${strings.titleErrorMsgTwo}`),
      description: Yup.string()
        .min(10, `${strings.descErrorMsgOne}`)
        .required(`${strings.descErrorMsgTwo}`),
      KM: Yup.string()
        .required(`${strings.required}`),
      price: Yup.object().shape({
        currency: Yup.string().required(`${strings.currencyError}`),
        price: Yup.number()
          .min(0, `${strings.priceErrorOne}`)
          .required(`${strings.priceErrorTwo}`),
      }),
    }),
  });
  const formik = useFormik({
    initialValues: {
      section: vehicleId,
      purpose: "",
      basicInformation: {
        title: "",
        description: "",
        price: {
          currency: "",
          price: 0,
        },
        category: "",
        brand: "",
        model: "",
        details: [],
        contactInformation: {
          nameSurname: "",
          contactList: [],
        },

      },

      internalFeatures: [],
      externalFeaturesNotDisclose: false,
      externalFeatures: [],
      internalFeaturesNotDisclose: false,
      safetyFeaturesNotDisclose: false,
      safetyFeatures: [],

      location: {
        country: "",
        city: "",
        district: "",
        neighborhood: "",
        complex: "",
        openAddress: "",
      },
      bodyParts: [],
      imageUrl: [],
      videoUrl: "",
      coverPhoto: ""
    },

    validationSchema,
    onSubmit: (values) => {
      handleSave()
    },
  });


  const addNewInternalFeature = () => {
    if (newInternalFeature === '' || newInternalFeature === ' ') {
      toast.error(strings.fill);
      return;
    } else {
      const newItem = {
        fieldName: newInternalFeature,
      };
      const currentInternalfeatures = formik.values.internalFeatures;
      const currentGetInternalfeatures = getInternalFeatures;

      // Check if an item with the same fieldName already exists
      const isDuplicate = currentInternalfeatures.some(
        (item) => item.fieldName === newInternalFeature
      );

      if (isDuplicate) {
        toast.error(strings.noDuplicate);
        return;
      }

      const updatedInternalfeatures = [...currentInternalfeatures, newItem];
      const updatedGetInternalFeatures = [...currentGetInternalfeatures, newItem];
      formik.setFieldValue("internalFeatures", updatedInternalfeatures);
      setNewInternalFeature("");
      setGetInternalFeatures(updatedGetInternalFeatures);
      setUpdatedInternaFeatures(updatedGetInternalFeatures)
      setShowModal(false);
    }
  };

  const addNewExternalFeature = () => {
    if (newExternalFeature.trim() === '') {
      toast.error(strings.fill);
      return;
    } else {
      const newItem = {
        fieldName: newExternalFeature,
      };

      const currentInternalfeatures = formik.values.externalFeatures;
      const currentExtFeatures = getExternalFeatures;

      // Check if an item with the same fieldName already exists
      const isExistingItem = currentInternalfeatures.some(
        (item) => item.fieldName === newExternalFeature
      );

      if (isExistingItem) {
        toast.error(strings.noDuplicate);
        return;
      }

      const updatedInternalfeatures = [...currentInternalfeatures, newItem];
      const updatedExtFeatures = [...currentExtFeatures, newItem];
      formik.setFieldValue("externalFeatures", updatedInternalfeatures);
      setNewExternalFeature("");
      setGetExternalFeatures(updatedExtFeatures);
      setUpdatedExternalFeatures(updatedExtFeatures)
      setExternalShowModal(false);
    }
  };

  const addNewSafetyFeature = () => {
    if (newSafetyFeature.trim() === '') {
      toast.error(strings.fill);
      return;
    } else {
      const newItem = {
        fieldName: newSafetyFeature,
      };
      const currentInternalfeatures = formik.values.safetyFeatures;
      const curentSafetyFeatures = getsafetyFeatures;

      // Check if an item with the same fieldName already exists
      const isDuplicate = currentInternalfeatures.some(
        (item) => item.fieldName === newSafetyFeature
      );
      if (isDuplicate) {
        toast.error(strings.noDuplicate);
        return;
      }

      const updatedInternalfeatures = [...currentInternalfeatures, newItem];
      const updatedSafetyFeatures = [...curentSafetyFeatures, newItem];
      formik.setFieldValue("safetyFeatures", updatedInternalfeatures);
      setNewSafetyFeature("")
      setGetSafetyFeatures(updatedSafetyFeatures);
      setUpdatedSafetyFeatures(updatedSafetyFeatures)
      setSafetyShowModal(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    fetch(`${uploadDataUrl}/v1/adds/upload-multiple`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .catch((err) => { });;
  };

  const handleSave = (values) => {
    submitData(values);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(
          `/v1/adds/section/feature?section=vehicle&language=${strings._language}`,
        );
        const filteredData = response.data.detail.filter((item) =>
          item.fieldName !== "Currency" && item.fieldName !== "Валюта" && item.fieldName !== "Valyuta" &&
          item.fieldName !== "Purpose" && item.fieldName !== "Телосложение" && item.fieldName !== "Maqsad" &&
          item.fieldName !== "Series" && item.fieldName !== "Ряд" && item.fieldName !== "Seriya"
        );
        setGetData(filteredData)
        setMyData(response.data.detail)
        setDropDownData(filteredData)
        setDetailedDataLength(response.data.detail.length - 3)
        setGetInternalFeatures(response.data.internalFeatures);
        setGetExternalFeatures(response.data.externalFeatures);
        setGetSafetyFeatures(response.data.saftyFeatures);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [strings._language]);

  const submitData = (data) => {
    if (selectedCategoryTitle !== 'Motorcycles' && selectedCategoryTitle !== 'Мотоциклы' && selectedCategoryTitle !== 'Mototsikllar' && selectedCategoryTitle !== 'ATV' && selectedCategoryTitle !== 'UTV' && selectedCategoryTitle !== 'квадроцикл' && selectedCategoryTitle !== 'УТВ' && formik.values.bodyParts.length < 13) {
      toast.error(`${strings.bodyPartsErr}`)
      return
    } else if (formik.values.basicInformation.category !== '63f71a6fe53fdd92a844cf22' && formik.values.basicInformation.category !== '63f722eee53fdd92a84526ed' && formik.values.basicInformation.category !== '63f723aae53fdd92a8452ec4' && formik.values.basicInformation.category !== "65a7d5c89f32be903d687bd4" && formik.values.basicInformation.category !== "63f71edfe53fdd92a844f321" && formik.values.basicInformation.category !== "6581423ba4410bdb6dd94367" && formik.values.basicInformation.category !== "63f72212e53fdd92a84520d0"  && !formik.values.externalFeaturesNotDisclose && formik.values.externalFeatures.length < 1) {
      toast.error(`${strings.externalFeatureError}`)
      setRequired(true)

    } else if (formik.values.basicInformation.category !== '63f71a6fe53fdd92a844cf22' && formik.values.basicInformation.category !== '63f722eee53fdd92a84526ed' && formik.values.basicInformation.category !== '63f723aae53fdd92a8452ec4' && formik.values.basicInformation.category !== "65a7d5c89f32be903d687bd4" && formik.values.basicInformation.category !== "63f71edfe53fdd92a844f321" && formik.values.basicInformation.category !== "6581423ba4410bdb6dd94367" && formik.values.basicInformation.category !== "63f72212e53fdd92a84520d0"  && !formik.values.internalFeaturesNotDisclose && formik.values.internalFeatures.length < 1) {
      toast.error(`${strings.internalFeatureError}`)
      setRequired(true)

    } else if (formik.values.basicInformation.category !== '63f71a6fe53fdd92a844cf22' && formik.values.basicInformation.category !== '63f722eee53fdd92a84526ed' && formik.values.basicInformation.category !== '63f723aae53fdd92a8452ec4' && formik.values.basicInformation.category !== "65a7d5c89f32be903d687bd4" && formik.values.basicInformation.category !== "63f71edfe53fdd92a844f321" && formik.values.basicInformation.category !== "6581423ba4410bdb6dd94367" && formik.values.basicInformation.category !== "63f72212e53fdd92a84520d0"  && !formik.values.safetyFeaturesNotDisclose && formik.values.safetyFeatures.length < 1) {
      toast.error(`${strings.safetyFeatureError}`)
      setRequired(true)
    } else if (!(whatsapp || telegram || call || sms)) {
      // Check if none of the contact options are selected
      toast.error(`${strings.contactError}`);
    } else {
      navigate("/vehicle-preview", {
        state: {
          formik: formik.values,
          basicInfo: {
            category: category,
            brand: brand,
            model: model
          },
          isEdit: true
        },
      });
    }

  };
  useEffect(() => {
    if (category === 'Motorcycles' || category === 'Мотоциклы' || category === 'Mototsikllar' || category === 'ATV' || category === 'UTV' || category === 'квадроцикл' || category === 'УТВ') {
      setSelectedCategoryTitle(category)
    } else {
      setSelectedCategoryTitle('')
    }
  }, [category])

  useEffect(() => {
    setCategory(basicInfo.category)
    setBrand(basicInfo.brand)
    setModel(basicInfo.model)
  }, [basicInfo])

  const handleInternalFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = internalFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...internalFeatures];
        newFeatures.splice(existingIndex, 1);
        setInternalFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setInternalFeatures([...internalFeatures, newItem]);
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedCategoryTitle === 'Motorcycles' || selectedCategoryTitle === 'ATV' || selectedCategoryTitle === 'UTV') {
      const filteredData = getData.filter((item) => item.fieldName !== 'Transmission Type' && item.fieldName !== 'Engine Size' && item.fieldName !== 'Wheel Drive')
      setGetData(filteredData)
    } else if (selectedCategoryTitle === 'Мотоциклы' || selectedCategoryTitle === 'квадроцикл' || selectedCategoryTitle === 'УТВ') {
      const filteredData = getData.filter((item) => item.fieldName !== 'Тип передачи' && item.fieldName !== 'Размер двигателя' && item.fieldName !== 'Полный привод')
      setGetData(filteredData)
    } else if (selectedCategoryTitle === 'Mototsikllar' || selectedCategoryTitle === 'ATV' || selectedCategoryTitle === 'UTV') {
      const filteredData = getData.filter((item) => item.fieldName !== 'Transmissiya turi' && item.fieldName !== 'Dvigatel hajmi' && item.fieldName !== `G'ildirakli haydovchi`)
      setGetData(filteredData)
    } else if (selectedCategoryTitle === '') {
      setGetData(dropDownData)
    }
  }, [selectedCategoryTitle])

  useEffect(() => {
    formik.setFieldValue("internalFeatures", internalFeatures)
  }, [internalFeatures])


  const handleExternalFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = externalFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...externalFeatures];
        newFeatures.splice(existingIndex, 1);
        setExternalFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setExternalFeatures([...externalFeatures, newItem]);
      }
    } else {
    }
  };


  useEffect(() => {
    formik.setFieldValue("externalFeatures", externalFeatures)
  }, [externalFeatures])


  const handleSaftyFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = safetyFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...safetyFeatures];
        newFeatures.splice(existingIndex, 1);
        setSafetyFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setSafetyFeatures([...safetyFeatures, newItem]);
      }
    } else {
    }
  };


  useEffect(() => {
    formik.setFieldValue("safetyFeatures", safetyFeatures)
  }, [safetyFeatures])

  useEffect(() => {
    formik.setFieldValue(
      "basicInformation.contactInformation.contactList",
      selectedOptions
    );
  }, [selectedOptions]);


  useEffect(() => {
    formik.setValues(editData)
    if (editData.basicInformation.contactInformation.contactList.includes("Whatsapp")) {
      setWhatsapp(true)
    }
    if (editData.basicInformation.contactInformation.contactList.includes("Call")) {
      setCall(true)
    }
    if (editData.basicInformation.contactInformation.contactList.includes("Sms")) {
      setSms(true)
    }
    if (editData.basicInformation.contactInformation.contactList.includes("Telegram")) {
      setTelegram(true)
    }
    editData.imageUrl.forEach((item, index) => {
      files.push({
        id: index,
        image: item,
      });
    });
    setVideoFile(editData.videoUrl)
    setInternalFeatures(editData.internalFeatures)
    setExternalFeatures(editData.externalFeatures)
    setSafetyFeatures(editData.safetyFeatures)
  }, [])

  const removeImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


  const handleRemoveVideo = () => {
    setVideoFile(null);
  };


  useEffect(() => {
    const newIntFeatures = editData?.internalFeatures.filter(
      (item) => !getData.some((dataItem) => dataItem.fieldName === item.fieldName)
    );

    const updatedIntFeatures = getData.concat(newIntFeatures);
    setUpdatedInternaFeatures(updatedIntFeatures)
    setGetInternalFeatures(updatedIntFeatures)

    const newExtFeatures = editData?.externalFeatures.filter(
      (item) => !getData.some((dataItem) => dataItem.fieldName === item.fieldName)
    );
    const updatedExtFeatures = getData.concat(newExtFeatures);
    setUpdatedExternalFeatures(updatedExtFeatures)
    setGetExternalFeatures(updatedExtFeatures)

    const newSafetyFeatures = editData?.safetyFeatures.filter(
      (item) => !getData.some((dataItem) => dataItem.fieldName === item.fieldName)
    );
    const updatedSafFeatures = getData.concat(newSafetyFeatures);
    setUpdatedSafetyFeatures(updatedSafFeatures)
    setGetSafetyFeatures(updatedSafFeatures)
  }, [getData])

  const updateImages = () => {
    const imageUrlSet = new Set();

    files.forEach((file) => {
      imageUrlSet.add(file.image);
    });

    const imageUrl = Array.from(imageUrlSet);

    formik.setFieldValue("imageUrl", imageUrl);
  };


  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[0].categories);
      })
      .catch((err) => { });;
  }, [strings._language]);

  useEffect(() => {
    axios
      .get(
        `${baseURL1}/v1/adds/brands-dropdown?catoryId=${typeof formik.values.basicInformation.category === "object" ? formik.values.basicInformation.category._id : formik.values.basicInformation.category}&language=${strings._language}`
      )
      .then((response) => {
        setModels(response.data);
      })
      .catch((err) => { });;
  }, [formik.values.basicInformation.category]);

  useEffect(() => {
    axios
      .get(
        `${baseURL1}/v1/adds/models-dropdown?brandId=${typeof formik.values.basicInformation.brand === "object" ? formik.values.basicInformation.brand._id : formik.values.basicInformation.brand}&language=${strings._language}`
      )
      .then((response) => {
        setModels1(response.data);
      })
      .catch((err) => { });;
  }, [formik.values.basicInformation.brand, strings._language]);

  useEffect(() => {
    apiClient
      .get(
        `/v1/user/notification`
      )
      .then((response) => {
        setNotificationCount(response.data.length)
        setNotificationData(response.data)
      })
      .catch((err) => { });;
  }, [])

  useEffect(() => {
    getData.forEach((data, index) => {
      if (data.fieldName === "Currency" || data.fieldName === "Валюта" || data.fieldName === "Valyuta") {
        formik.setFieldValue(`basicInformation.details[${index}]`, {
          fieldName: data.fieldName,
          value: formik.values.basicInformation.price.currency, // Replace "currency value" with the actual value you want to set
          imageUrl: data.imageUrl
        });
      }
      if (data.fieldName === "purpose" || data.fieldName === "Телосложение" || data.fieldName === "Maqsad") {
        formik.setFieldValue(`basicInformation.details[${index}]`, {
          fieldName: data.fieldName,
          value: formik.values.purpose, // Replace "currency value" with the actual value you want to set
          imageUrl: data.imageUrl
        });
      }
    });
  }, [formik.values.basicInformation.price.currency, formik.values.purpose]);


  return (
    <>
      <div>
        <h4 className="text-center">{strings.editAd}</h4>
      </div>

      {/*section 3*/}

      <div className="container mx-auto   mt-4">
        <div className="bg-white flex justify-evenly pb-4  rounded-lg shadow-md flex-wrap  ">
          <div className="mt-5">
            <h6 className="font-bold">{strings.select} {strings.purpose}</h6>
            <select
              className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
              onChange={(e) => {
                formik.setFieldValue("purpose", e.target.value);
              }}
              value={formik.values.purpose}
            >
              <option>{strings.select}</option>

              <option value="sale">{strings.sale}</option>
              <option value="rent">{strings.rent}</option>
            </select>
            {formik.touched.purpose && formik.errors.purpose ? (
              <div className="text-red-500">{formik.errors.purpose}</div>
            ) : null}
          </div>
          <div className="mt-5">
            <h6 className="font-bold">{strings.select} {strings.category}</h6>
            <select
              className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
              id="category"
              onChange={(e) => {
                const selectedBrand = brands.find(brand => brand._id === e.target.value);
                formik.setFieldValue("basicInformation.category", selectedBrand._id);
                setCategory(selectedBrand.title);
                if (selectedBrand.title === 'Motorcycles' || selectedBrand.title === 'Мотоциклы' || selectedBrand.title === 'Mototsikllar' || selectedBrand.title === 'ATV' || selectedBrand.title === 'UTV' || selectedBrand.title === 'квадроцикл' || selectedBrand.title === 'УТВ') {
                  setSelectedCategoryTitle(selectedBrand.title)
                } else {
                  setSelectedCategoryTitle('')
                }

              }}
              value={typeof formik.values.basicInformation.category === "object" ? formik.values.basicInformation.category._id : formik.values.basicInformation.category}
            >
              <option value="">{strings.select}</option>

              {brands.sort((a, b) => a.title.localeCompare(b.title)).map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.title}
                </option>
              ))}
            </select>

            {formik.touched.basicInformation?.category &&
              formik.errors.basicInformation?.category ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.category}
              </div>
            ) : null}
          </div>
          <div className="mt-5">
            <h6 className="font-bold">{strings.select} {strings.brand}</h6>
            <select
              className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
              id="model"
              onChange={(e) => {
                const selectedModel = models.find(model => model._id === e.target.value);
                formik.setFieldValue("basicInformation.brand", selectedModel._id);
                setBrand(selectedModel.title);
              }}
              value={typeof formik.values.basicInformation.brand === "object" ? formik.values.basicInformation.brand._id : formik.values.basicInformation.brand}
            >
              <option value=''>{strings.select}</option>

              {models.sort((a, b) => a.title.localeCompare(b.title)).map((model) => (
                <option key={model._id} value={model._id}>
                  {model.title}
                </option>
              ))}
            </select>

            {formik.touched.basicInformation?.brand &&
              formik.errors.basicInformation?.brand ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.brand}
              </div>
            ) : null}
          </div>
          <div className="mt-5">
            <h6 className="font-bold">{strings.select} {strings.model}</h6>
            <select
              id="model"
              className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
              onChange={(e) => {
                const selectedModel = models1.find(model => model._id === e.target.value);
                formik.setFieldValue("basicInformation.model", selectedModel._id);
                setModel(selectedModel.title);
              }}
              value={typeof formik.values.basicInformation.model === "object" ? formik.values.basicInformation.model._id : formik.values.basicInformation.model}
            >
              <option value="">{strings.select}</option>

              {models1.map((model) => (
                <option key={model._id} value={model._id}>
                  {model.title}
                </option>
              ))}
            </select>

            {formik.touched.basicInformation?.model &&
              formik.errors.basicInformation?.model ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.model}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="container mx-auto  mt-4">
        <div className="bg-white p-4 rounded-lg shadow-md ">
          <div>
            <h6 className="pt-2 font-bold">{strings.basicInfo}</h6>

            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.title}
              onChange={(e) => {
                formik.setFieldValue("basicInformation.title", e.target.value);
              }}
              value={formik.values.basicInformation.title}
            />
            {formik.touched.basicInformation?.title &&
              formik.errors.basicInformation?.title ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.title}
              </div>
            ) : null}
          </div>

          <div>
            <textarea
              className="w-full bg-white  border-2 p-3 rounded-md mt-3 "
              type="text"
              placeholder={strings.desc}
              onChange={(e) => {
                formik.setFieldValue(
                  "basicInformation.description",
                  e.target.value
                );
              }}
              value={formik.values.basicInformation.description}
            />
            {formik.touched.basicInformation?.description &&
              formik.errors.basicInformation?.description ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.description}
              </div>
            ) : null}
          </div>

          <hr className="mt-5 w-full" />

          <div className="container mx-auto">
            <h6 className="font-bold">KM</h6>
            <div className="">
              <input
                className="w-72 border-2  bg-white shadow-md p-3 rounded-full"
                type="number"
                placeholder={strings.typeHere}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    formik.setFieldValue(
                      "basicInformation.KM",
                      ''
                    );
                  } else {
                    formik.setFieldValue(
                      "basicInformation.KM",
                      e.target.value
                    );
                  }

                }}
                value={formik.values.basicInformation.KM}
              />
              {<div className="text-red-500">
                {formik?.errors?.basicInformation?.KM}
              </div>}
            </div>

            <h6 className="pt-2 font-bold">{strings.price}</h6>

            <div className="flex justify-between flex-col md:flex-row">
              <div className="col-md-5 mt-3">
                <select
                  placeholder="dropDown"
                  name="dropDown"
                  className="custom-select w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1 col-md-12"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "basicInformation.price.currency",
                      e.target.value
                    );
                  }}
                  value={formik.values.basicInformation.price.currency}
                >
                  <option>{strings.select}</option>
                  {myData?.find(obj => obj.fieldName === 'Currency' || obj.fieldName === "Валюта" || obj.fieldName === "Valyuta")?.dropDown.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                {formik.touched.basicInformation?.price?.currency &&
                  formik.errors.basicInformation?.price?.currency ? (
                  <div className="text-red-500">
                    {formik.errors.basicInformation?.price?.currency}
                  </div>
                ) : null}
              </div>
              <div className="col-md-5 mt-3">
                <CurrencyInput
                  min={0}
                  decimalsLimit={2}
                  name='price'
                  id='price'
                  className="w-full bg-white  border-1 shadow-md p-3 rounded-full "
                  placeholder={strings.price}
                  onValueChange={(value, name, values) => formik.setFieldValue(
                    "basicInformation.price.price",
                    value
                  )}

                  value={formik.values.basicInformation.price.price}
                />
                {formik.touched.basicInformation?.price?.price &&
                  formik.errors.basicInformation?.price?.price ? (
                  <div className="text-red-500">
                    {formik.errors.basicInformation?.price?.price}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Dropdown section */}
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 py-5 justify-center">
              {
                getData.map((data, index) => {
                  if (data.fieldName === "Currency" || data.fieldName === "Валюта" || data.fieldName === "Valyuta") {
                    return null;
                  }
                  return (
                    <div key={index}>
                      <h6 className="mt-2 font-bold">{data.fieldName}</h6>
                      <select
                        placeholder="dropDown"
                        name="dropDown"
                        className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
                        onChange={(e) =>
                          formik.setFieldValue(`basicInformation.details[${index}]`, {
                            fieldName: data.fieldName,
                            value: e.target.value,
                            imageUrl: data.imageUrl
                          })
                        }
                        value={formik.values?.basicInformation.details[index]?.value}
                      >
                        <option>{strings.select}</option>
                        <option>{formik.values?.basicInformation.details[index]?.value}</option>

                        {
                          data.dropDown.length === 0 ? (
                            <>
                              <option>{strings.yes}</option>
                              <option>{strings.no}</option>
                            </>
                          ) : (
                            data.dropDown.map((option) => {
                              if (
                                formik.values?.basicInformation.details[index]?.value &&
                                option === formik.values.basicInformation.details[index].value
                              ) {
                                return null; // Skip this option
                              }

                              return (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              );
                            })
                          )}
                      </select>
                      {required && (
                        <div className="text-red-500">
                          {strings.required}
                        </div>
                      )}
                    </div>
                  );
                })
              }
            </div>

            <h5 className="pt-3 font-bold">{strings.contactInfo}</h5>

            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="whatsapp"
                    value="whatsapp"
                    onClick={() => {
                      if (whatsapp) {
                        setWhatsapp(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Whatsapp"
                          )
                        );
                      } else {
                        setWhatsapp(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Whatsapp",
                        ]);
                      }
                    }}
                    checked={whatsapp}
                  />
                </div>
                <div>{strings.wtp}</div>
              </label>
            </div>
            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="telegram"
                    value="telegram"
                    onClick={() => {
                      if (telegram) {
                        setTelegram(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Telegram"
                          )
                        );
                      } else {
                        setTelegram(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Telegram",
                        ]);
                      }
                    }}
                    checked={telegram}
                  />
                </div>
                <div>{strings.teleg}</div>
              </label>
            </div>
            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="call"
                    value="call"
                    onClick={() => {
                      if (call) {
                        setCall(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Call"
                          )
                        );
                      } else {
                        setCall(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Call",
                        ]);
                      }
                    }}
                    checked={call}
                  />
                </div>
                <div>{strings.call}</div>
              </label>
            </div>
            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="sms"
                    value="sms"
                    onClick={() => {
                      if (sms) {
                        setSms(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Sms"
                          )
                        );
                      } else {
                        setSms(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Sms",
                        ]);
                      }
                    }}
                    checked={sms}
                  />
                </div>
                <div>{strings.sms}</div>
              </label>
            </div>

          </div>

          {/*........................................................*/}
          {
            (selectedCategoryTitle === '' && formik.values.basicInformation.category !== '65a7d5c89f32be903d687bd4' && formik.values.basicInformation.category !== '6581423ba4410bdb6dd94367' &&
              formik.values.basicInformation.category !== '63f71edfe53fdd92a844f321' && formik.values.basicInformation.category !== '63f72212e53fdd92a84520d0'
            ) && (
              <>
                <hr className="mt-5" />
                <div className="">
                  <div className=" flex mt-4">
                    <label className="flex">
                      <input
                        type="radio"
                        className="h-8 w-8 text-purple-500"
                        value="formik.values.internalFeaturesNotDisclose"
                        onClick={() => {
                          if (formik.values.internalFeaturesNotDisclose) {
                            formik.setFieldValue(
                              "internalFeaturesNotDisclose",
                              false
                            );
                          } else {
                            formik.setFieldValue("internalFeaturesNotDisclose", true);
                          }
                        }}
                        checked={formik.values.internalFeaturesNotDisclose}
                      />
                      <div className="ml-2 text-gray-700 font-bold py-1">
                        {strings.disclose} {strings.internalFeatures}
                      </div>
                    </label>
                  </div>
                </div>
                <h5 className="pt-3 font-bold">{strings.internalFeatures}</h5>

                <div className="">
                  <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-3 mt-4">
                    {manualAd ? (
                      updatedInternaFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                        const isActive =
                          internalFeatures.findIndex(
                            (feature) => feature.fieldName === item.fieldName
                          ) !== -1;
                        return (
                          <div
                            className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                              }`}
                            key={item._id}
                            onClick={() =>
                              handleInternalFeatures(item.fieldName, item.imageUrl)
                            }
                          >
                            <img className="w-12" src={item.imageUrl} alt="" />
                            <h6 className="p-1 text-center text-sm">{item.fieldName}</h6>
                          </div>
                        );
                      })
                    ) : (
                      getInternalFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                        const isActive =
                          internalFeatures.findIndex(
                            (feature) => feature.fieldName === item.fieldName
                          ) !== -1;
                        return (
                          <div
                            className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                              }`}
                            key={item._id}
                            onClick={() =>
                              handleInternalFeatures(item.fieldName, item.imageUrl)
                            }
                          >
                            <img className="w-12" src={item.imageUrl} alt="" />
                            <h6 className="p-1 text-center text-sm">{item.fieldName}</h6>
                          </div>
                        );
                      })
                    )}
                  </div>


                  <div className="text-red-400 flex justify-center mt-5">
                    <button onClick={() => setShowModal(true)}> +{strings.addManually}</button>
                  </div>
                  <Modal
                    size="md"
                    style={{ marginTop: "50px" }}
                    show={showModal}
                    onHide={() => setShowModal(false)}
                  >
                    <div className="p-3 flex flex-col items-center ">
                      <h6 className="pt-2"> +{strings.addManually}</h6>

                      <input
                        className="w-full bg-white  border-2 p-2 rounded-full "
                        type="text"
                        placeholder={strings.itemName}
                        onChange={(e) => setNewInternalFeature(e.target.value)}
                        value={newInternalFeature}
                      />

                      <button
                        className="w-40 p-2 rounded-full text-white mt-2 bg-orange-500"
                        onClick={addNewInternalFeature}
                      >
                        {strings.add}
                      </button>
                    </div>
                  </Modal>

                  <div className="">
                    <div className=" flex mt-4">
                      <label className="flex">
                        <input
                          type="radio"
                          className="h-8 w-8 text-purple-500"
                          value="formik.values.externalFeaturesNotDisclose"
                          onClick={() => {
                            if (formik.values.externalFeaturesNotDisclose) {
                              formik.setFieldValue(
                                "externalFeaturesNotDisclose",
                                false
                              );
                            } else {
                              formik.setFieldValue(
                                "externalFeaturesNotDisclose",
                                true
                              );
                            }
                          }}
                          checked={formik.values.externalFeaturesNotDisclose}
                        />
                        <div className="ml-2 text-gray-700 font-bold py-1">
                          {strings.disclose} {strings.extFeatures}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <h5 className="pt-2 font-bold">{strings.extFeatures}</h5>

                <div className="grid   grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7  gap-3 mt-4">
                  {manualAd ? (
                    updatedExternalFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                      const isActive =
                        externalFeatures.findIndex(
                          (feature) => feature.fieldName === item.fieldName
                        ) !== -1;
                      return (
                        <div
                          className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                            }`}
                          key={item._id}
                          onClick={() =>
                            handleExternalFeatures(item.fieldName, item.imageUrl)
                          }
                        >
                          <img className="w-12" src={item.imageUrl} alt="" />
                          <h6 className="p-1 text-center text-sm">{item.fieldName}</h6>
                        </div>
                      );
                    })
                  ) : (
                    getExternalFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                      const isActive =
                        externalFeatures.findIndex(
                          (feature) => feature.fieldName === item.fieldName
                        ) !== -1;
                      return (
                        <div
                          className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                            }`}
                          key={item._id}
                          onClick={() =>
                            handleExternalFeatures(item.fieldName, item.imageUrl)
                          }
                        >
                          <img className="w-12" src={item.imageUrl} alt="" />
                          <h6 className="p-1 text-center text-sm">{item.fieldName}</h6>
                        </div>
                      );
                    })
                  )}

                  <div className="text-red-400 flex justify-center mt-5">
                    <button onClick={() => setExternalShowModal(true)}>
                      {" "}
                      +{strings.addManually}
                    </button>
                  </div>
                  <Modal
                    size="md"
                    style={{ marginTop: "50px" }}
                    show={showExternalModal}
                    onHide={() => setExternalShowModal(false)}
                  >
                    <div className="p-3 flex flex-col items-center ">
                      <h6 className="pt-2"> +{strings.addManually}</h6>

                      <input
                        className="w-full bg-white  border-2 p-2 rounded-full "
                        type="text"
                        placeholder={strings.itemName}
                        onChange={(e) => setNewExternalFeature(e.target.value)}
                        value={newExternalFeature}
                      />

                      <button
                        className="w-40 p-2 rounded-full text-white mt-2 bg-orange-500"
                        onClick={addNewExternalFeature}
                      >
                        {strings.add}
                      </button>
                    </div>
                  </Modal>
                </div>

                <div className="mt-5">
                  <div className=" flex mt-4">
                    <label className="flex">
                      <input
                        type="radio"
                        className="h-8 w-8 text-purple-500"
                        value="formik.values.safetyFeaturesNotDisclose"
                        onClick={() => {
                          if (formik.values.safetyFeaturesNotDisclose) {
                            formik.setFieldValue("safetyFeaturesNotDisclose", false);
                          } else {
                            formik.setFieldValue("safetyFeaturesNotDisclose", true);
                          }
                        }}
                        checked={formik.values.safetyFeaturesNotDisclose}
                      />
                      <div className="ml-2 text-gray-700 text-sm font-bold py-1">
                        {strings.disclose} {strings.safetyFeatures}
                      </div>
                    </label>
                  </div>
                </div>
                <h5 className="pt-4 font-bold">{strings.safetyFeatures}</h5>
                <div className="grid   grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 mt-4 gap-3">
                  {manualAd ? (
                    updatedSafetyFeatures?.map((item, index) => {
                      const isActive =
                        safetyFeatures.findIndex(
                          (feature) => feature.fieldName === item.fieldName
                        ) !== -1;
                      return (
                        <div
                          className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                            }`}
                          key={item._id}
                          onClick={() =>
                            handleSaftyFeatures(item.fieldName, item.imageUrl)
                          }
                        >
                          <img className="w-12" src={item.imageUrl} alt="" />
                          <h6 className="p-1 text-center text-sm">{item.fieldName}</h6>
                        </div>
                      );
                    })
                  ) : (
                    getsafetyFeatures.map((item, index) => {
                      const isActive =
                        safetyFeatures.findIndex(
                          (feature) => feature.fieldName === item.fieldName
                        ) !== -1;
                      return (
                        <div
                          className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                            }`}
                          key={item._id}
                          onClick={() =>
                            handleSaftyFeatures(item.fieldName, item.imageUrl)
                          }
                        >
                          <img className="w-12" src={item.imageUrl} alt="" />
                          <h6 className="p-1 text-center text-sm">{item.fieldName}</h6>
                        </div>
                      );
                    })
                  )}
                  <div className="text-red-400 flex justify-center mt-5">
                    <button onClick={() => setSafetyShowModal(true)}>
                      {" "}
                      +{strings.addManually}
                    </button>
                  </div>
                  <Modal
                    size="md"
                    style={{ marginTop: "50px" }}
                    show={showSafetyModal}
                    onHide={() => setSafetyShowModal(false)}
                  >
                    <div className="p-3 flex flex-col items-center ">
                      <h6 className="pt-2"> +{strings.addManually}</h6>

                      <input
                        className="w-full bg-white  border-2 p-2 rounded-full "
                        type="text"
                        placeholder="Item Name"
                        onChange={(e) => setNewSafetyFeature(e.target.value)}
                        value={newSafetyFeature}
                      />

                      <button
                        className="w-40 p-2 rounded-full text-white mt-2 bg-orange-500"
                        onClick={addNewSafetyFeature}
                      >
                        {strings.add}
                      </button>
                    </div>
                  </Modal>
                </div>
                {/* body parts */}
                <div className="mt-5">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 font-bold  py-4 whitespace-nowrap"
                                >
                                  {strings.bodyParts}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs   text-green-500 uppercase tracking-wider"
                                >
                                  {strings.original}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs  text-red-300 uppercase tracking-wider"
                                >
                                  {strings.painted}
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs  text-orange-400 uppercase tracking-wider"
                                >
                                  {strings.replaced}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.frontBumper}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    style={{
                                      color: "red"
                                    }}
                                    type="radio"
                                    name={strings.frontBumper}
                                    value="original"
                                    className="h-8 w-8"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.frontBumper &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.frontBumper}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.frontBumper &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.frontBumper}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.frontBumper &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>
                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.engineBonnet}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.engineBonnet}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.engineBonnet &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.engineBonnet}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.engineBonnet &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.engineBonnet}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.engineBonnet &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.ceiling}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.ceiling}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.ceiling &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.ceiling}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.ceiling &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.ceiling}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.ceiling &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.rightFrontFendor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightFrontFendor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightFrontFendor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightFrontFendor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightFrontFendor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightFrontFendor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightFrontFendor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.rightFrontDoor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightFrontDoor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightFrontDoor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightFrontDoor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightFrontDoor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightFrontDoor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightFrontDoor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.rightRearDoor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightRearDoor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightRearDoor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightRearDoor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightRearDoor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightRearDoor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightRearDoor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.leftFrontFendor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftFrontFendor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftFrontFendor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftFrontFendor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftFrontFendor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftFrontFendor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftFrontFendor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.rightRearFendor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightRearFendor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightRearFendor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightRearFendor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightRearFendor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rightRearFendor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rightRearFendor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.leftFrontDoor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftFrontDoor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftFrontDoor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftFrontDoor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftFrontDoor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftFrontDoor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftFrontDoor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.leftRearDoor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftRearDoor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftRearDoor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftRearDoor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftRearDoor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftRearDoor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftRearDoor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.leftRearFendor}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftRearFendor}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftRearFendor &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftRearFendor}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftRearFendor &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.leftRearFendor}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.leftRearFendor &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.tailgate}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.tailgate}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.tailgate &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.tailgate}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.tailgate &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.tailgate}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.tailgate &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>

                              <tr onChange={handleOptionChange}>
                                <td className="px-6 font-bold  py-4 whitespace-nowrap">
                                  {strings.rearBumper}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rearBumper}
                                    value="original"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rearBumper &&
                                        option.value === "original"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rearBumper}
                                    value="painted"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rearBumper &&
                                        option.value === "painted"
                                    )}
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={strings.rearBumper}
                                    value="replaced"
                                    className="h-8 w-8 text-purple-500"
                                    checked={formik.values.bodyParts.some(
                                      (option) =>
                                        option.fieldName === strings.rearBumper &&
                                        option.value === "replaced"
                                    )}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
          <div className="container mt-3">
            <div>Location</div>
            <div className="mt-2">
              <input type="text" placeholder={strings.city} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.city', e.target.value)}
                value={formik.values.location.city}
                readOnly={isLocationAvailable}
              />
            </div>
            <div className="mt-4">
              <input type="text" placeholder={strings.distt} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.district', e.target.value)}
                value={formik.values.location.district}
                readOnly={isLocationAvailable}
              />
            </div>
            <div className="mt-4">
              <input type="text" placeholder={strings.country} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.country', e.target.value)}
                value={formik.values.location.country}
                readOnly={isLocationAvailable}
              />
            </div>
          </div>


          <div
            className="container mt-3 h-[450px]"
          >

            <OpenStreetMap
              style={{
                height: '400px'
              }}
              formik={formik}
              setIsLocationAvailable={setIsLocationAvailable}
            />
          </div>


          <hr className="w-full mt-4" />

          {/*Upload Images*/}
          <h3 className="text-sm mt-5">{strings.upload} {strings.image}</h3>
          <div className="col-12 bg-white shadow-lg rounded-lg mt-4">
            <div className="ml-auto mr-auto w-full justify-center p-3">
              <h6 className="text-center"> {/* Add the 'text-center' class */}
                {strings.upload} {strings.images}
              </h6>
              <form>
                <div className="flex w-100 justify-content-center"> {/* Add the 'justify-content-center' class */}
                  {Array.from(new Set(files.map(file => file.image))).map((image, index) => (
                    <div className="m-2" key={index}>
                      <div className="image-container">
                        <button className="remove-image" onClick={() => removeImage(index)}>
                          <FaTimes />
                        </button>
                        <img src={`${image}`} alt="" className="h-36 w-40" />

                      </div>
                    </div>
                  ))}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      handleImageUpload(e.target.files);
                    }}
                  />
                </div>
                <div className="col-md-12 flex justify-center mt-2">
                  <div
                    onClick={handleClick}
                    disabled={imageLoading}
                    style={{
                      width: '152px',
                      height: '50px',
                      background: '#F77A23 0% 0% no-repeat padding-box',
                      boxShadow: ' 0px 10px 10px #00000012',
                      borderRadius: '17px',
                      opacity: 1,
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    {imageLoading ? strings.uploading : `${strings.add} ${strings.image}`}
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Cover Photo */}
          <h3 className="text-sm mt-5">
            {strings.upload} {strings.coverPhoto}
          </h3>
          <div className="col-12 bg-white shadow-lg rounded-lg mt-4">
            <div className="ml-auto mr-auto w-full justify-center p-3">
              <h6 className="text-center">
                {" "}
                {/* Add the 'text-center' class */}
                {strings.upload} {strings.coverPhoto}
              </h6>
              <div className="flex w-100 justify-content-center">
                {" "}
                {
                  formik.values.coverPhoto !== '' && (
                    <div className="m-2" >
                      <div className="image-container">

                        <img src={`${formik?.values?.coverPhoto}`} alt="" className="h-36 w-40" />
                      </div>
                    </div>
                  )
                }
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={hiddenCoverFileInput}
                  onChange={(e) => {
                    uploadCoverPhoto(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 flex justify-center mt-2">
                <button
                  onClick={handleCoverPhotoClick}
                  className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                  disabled={coverPhotoLoading}
                >
                  {coverPhotoLoading
                    ? strings.uploading
                    : `${strings.add} ${strings.image}`}
                </button>
              </div>
            </div>
          </div>


          <div className="container mt-4 h-max p-2">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <h3 className="text-sm">{strings.upload} {strings.video}</h3>
                <div className="uploadimage">
                  <h4>{strings.upload} {strings.video}</h4>
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => {
                      handleVideoUpload(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    ref={hiddenVideoFileInput}
                  />

                  {videoFile && (
                    <div className="video-container">
                      <button className="remove-video" onClick={handleRemoveVideo}>
                        <FaTimes />
                      </button>
                      <video controls className="h-32 w-48">
                        <source src={videoFile} type={videoFile.type} />
                      </video>
                    </div>
                  )}

                  <div className="d-flex justify-content-center">
                    {
                      !videoFile && (
                        <button
                          className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                          onClick={handleVideoClick}
                          disabled={videoLoading}
                        >
                          {videoLoading ? strings.uploading : `${strings.add} ${strings.video}`}
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-4 ">
            <button
              type="submit"
              className="w-80 p-3 rounded-full text-white mb-5 bg-orange-500"
              onClick={formik.handleSubmit}
            >
              {strings.next}
            </button>
          </div>
        </div>
      </div>

      {/*footer*/}
      <Footer strings={strings} />
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          {
            notificationData.map((data, index) => (
              <div key={index}
                className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
              >
                <p style={{ fontWeight: 'bold' }}>{index + 1}. {data.title}</p>
                <p>{data.message}</p>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default EditVehicle;
