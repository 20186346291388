import React, { useState, useEffect } from 'react'
import PackageCard from './Card';
import apiClient from '../../utils/apiClient';
import { ClipLoader } from 'react-spinners';
import AdInformation from "../../images/AdInformation.svg";
import Revieworange from "../../images/Revieworange.svg";
import Package from "../../images/packageorange.svg";
import strings from '../../utils/locals/languages';
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { generateOrderNumber } from '../../utils/generateOrderNumber';


const Plans = ({ isEditOrCreate, data }) => {

    const [userPackagesLoading, setUserPackagesLoading] = useState(true)
    const [userPackages, setUserPackages] = useState([])
    const [standardPackageLoading, setStandardPackageLoading] = useState(true)
    const [standardPackage, setStandardPackage] = useState(null)
    const [packagesLoading, setPackagesLoading] = useState(true)
    const [freeAds, setFreeAds] = useState(0)
    const [packages, setPackages] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [freePlanLoading, setFreePlanLoading] = useState(false)
    const navigate = useNavigate()


    const [remainingAds, setRemainingAds] = useState(0)

    const handlePayment = async (price) => {

        const SERVICE_ID = process.env.REACT_APP_PAYMENT_SERVICE_ID
        const MERCHANT_ID = process.env.REACT_APP_PAYMENT_MERCHANT_ID
        const TRANSACTION_PARAM = await generateOrderNumber()
        const paymentUrl = `https://my.click.uz/services/pay/?service_id=${SERVICE_ID}&merchant_id=${MERCHANT_ID}&amount=${price}&transaction_param=${TRANSACTION_PARAM}&
            &return_url=https://www.egasi.net/select-package&card_type=humo`;
        window.location.href = paymentUrl;
    };

    const updateUserAdsCount = async (selectedPackage) => {
        try {
            await apiClient
                .put(`/v1/user/update-free-ads`)

        } catch (error) {
        }
    }

    const createAd = async () => {

        try {


            if (isEditOrCreate === "edit") {
                await apiClient.put(`/v1/adds/update-adds/${data._id}`, data);
                toast.success(`${strings.adUpdateMsg}`);
                Cookies.remove("manageAd");
            } else {
                if (data.basicInformation.area) {
                    await apiClient.post("v1/adds/create-realestate-adds", data);
                    updateUserAdsCount()

                } else {
                    await apiClient.post("v1/adds/create-vehicle-adds", data);
                    updateUserAdsCount()

                }
                toast.success(`${strings.adCreateMsg}`);
                sessionStorage.removeItem("isEditOrCreate");
                sessionStorage.removeItem("vehicleData");
            }

            navigate('/');
        } catch (err) {
            toast.error(isEditOrCreate === "edit" ? `${strings.adUpdateErrorMsg}` : `${strings.adCreateErrorMsg}`);
        } finally {
            setIsLoading(false);
            setFreePlanLoading(false)
        }
    };
    const handleReturn = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('payment_status');

        if (status === 2) {
            try {
                await createAd()

            } catch (error) {
                toast.error("Payment was unsuccessfull")
            }
        } else {
            toast.error("Payment was unsuccessfull")
        }
    };

    window.onload = handleReturn;


    const getStandardPackage = async () => {
        try {
            const response = await apiClient.get(`/v1/user/get-standard-package?language=${strings._language}`)

            setStandardPackage(response.data.package)
            setStandardPackageLoading(false)
        } catch {
            setStandardPackage(null)
        }
    }

    const getUserPackages = async () => {
        try {
            const response = await apiClient.get(`/v1/user/get-my-packages`)

            setUserPackages(response.data.user.packages)
            setRemainingAds(response.data.user.totalRemainingAds)
            setFreeAds(response.data.user.freeAds)
            setUserPackagesLoading(false)
        } catch(error) {
            toast.error(error.response.data.msg)
            setUserPackages([])
        }
    }

    const getPackages = async () => {
        try {
            const response = await apiClient.get(`/v1/user/get-packages?language=${strings._language}`)

            setPackages(response.data.packages)
            setPackagesLoading(false)
        } catch {
            setUserPackages([])
        }
    }

    useEffect(() => {
        getStandardPackage()
        getUserPackages()
        getPackages()
    }, [])


    return (
        <>
            <div className=" flex justify-center mt-4">
                <div>
                    <img className="w-16 ms-3" src={AdInformation} alt="" />
                    <p
                        className="py-2 md:ml-0 ml-2 text-sm text-center"
                    >
                        {strings.adInfo}
                    </p>
                </div>
                <div>
                    <p
                        flex
                        className="  w-36 mt-4  text"
                        style={{ height: "1px", background: "#F37521" }}
                    ></p>
                </div>

                <div>
                    <img className="w-16" src={Revieworange} alt="" />
                    <p
                        className="py-2 md:ml-0 ml-2 text-sm text-center"
                    >
                        {strings.reveiews}
                    </p>
                </div>
                <div>
                    <p
                        flex
                        className="  w-36 mt-4  text "
                        style={{ height: "1px", background: "gray" }}
                    ></p>
                </div>

                <div>
                    <img className="w-16" src={Package} alt="" />
                    <p
                        className="py-2 text-[#F37521] md:mr-0 "
                        style={{ color: "gray", fontSize: "14px", fontWeight: "bold"}}
                    >
                        {strings.pkg}
                    </p>
                </div>
            </div>

            <div className="flex flex-col p-6">
                <h2 className='mb-6 text-3xl font-bold'>{strings.pleaseSelectPlan}</h2>
                <h1 className="text-2xl">{strings.totalAdsRemaining} <span className='text-[#F37521]' style={{ fontWeight: 'bold' }}>{remainingAds}</span></h1>
                <h1 className="text-2xl">{strings.freeAdsRemaining} <span className='text-[#F37521]' style={{ fontWeight: 'bold' }}>{freeAds}</span></h1>

                {
                    freeAds > 0 && <div className='bg-[#F37521] text-xl font-bold rounded-full text-center w-80 p-3 text-white mt-4 mb-4 cursor-pointer'
                        onClick={() => {
                            setFreePlanLoading(true)
                            createAd()
                        }}
                    >
                        {
                            freePlanLoading ? <ClipLoader size={10} color='#fff' /> : strings.proceedWithFreePlan
                        }
                    </div>
                }

                <div className="flex flex-wrap gap-6">
                    {
                        standardPackageLoading ? <ClipLoader  /> : (
                            <div className="bg-gray-100 shadow-lg h-[300px] w-80 rounded-md flex flex-col items-center ">
                                <div className="bg-[rgb(243,117,33)] rounded-t-lg text-white w-full text-center py-2 text-lg font-bold">{strings.standardPackage}</div>
                                <div className="flex-1 my-4 w-[90%] h-[270px] text-base">{standardPackage.text.slice(0, 80)}</div>
                                <div className="text-xl flex w-[90%] justify-between mb-2">
                                    <div>{strings.price}</div>
                                    <div>{standardPackage.currency}{standardPackage.price}</div>
                                </div>
                                <button className="bg-[#F37521] text-white py-2 px-4 rounded-full mb-4 mt-4 hover:bg-[#D3621E]"
                                    onClick={() => handlePayment(standardPackage.price)}
                                >
                                    {
                                        isLoading ? <ClipLoader size={10} color='#fff' /> : 'Proceed'
                                    }
                                </button>
                            </div>
                        )
                    }
                </div>

                <h3 className='mb-6 text-lg font-bold mt-5'>{strings.boughtPackages}</h3>
                {
                    userPackagesLoading ? (
                        <ClipLoader />
                    ) : (
                        userPackages.length === 0 ? (
                            <div className='text-sm'>{strings.noPackageFound}</div>
                        ) : (
                            <div className="flex flex-wrap gap-6">
                                {userPackages.map((pkg, index) => (
                                    <PackageCard key={index} package={pkg} buy={false} isEditOrCreate={isEditOrCreate} data={data} />
                                ))}
                            </div>
                        )
                    )
                }


                <h3 className='mb-6 text-lg font-bold mt-5'>{strings.otherPackages}</h3>
                <div className="flex flex-wrap gap-6">
                    {
                        packagesLoading ? (
                            <ClipLoader />
                        ) : (
                            packages.length === 0 ? (
                                <div className='text-sm'>{strings.noPackageFound}</div>
                            ) : (
                                <div className="flex flex-wrap gap-6">
                                    {packages.map((pkg, index) => (
                                        <PackageCard key={index} package={pkg} buy={true} isEditOrCreate={isEditOrCreate} data={data} />
                                    ))}
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </>

    )
}

export default Plans